.dropdown-field {
    @media (min-width: $md-breakpoint) {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    label {
        margin-bottom: 8px;
        display: inline-block;

        @media (min-width: $md-breakpoint) {
            margin-bottom: 0px;
        }
    }

    input {
        width: 100%;
        height: 40px;
        border-radius: 6px;
        border: solid 1px;
        background-color: #ffffff;
        border-color: $cool-grey;
        padding-left: 15px;
        outline: 0;

        @media (min-width: $md-breakpoint) {
            width: 200px;
        }
        @media (min-width: $lg-breakpoint) {
            width: 300px;
        }

        &::placeholder {
        }
    }
}
