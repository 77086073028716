@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap");

*,
*:before,
*:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
}

body {
    font-family: "Open Sans", sans-serif !important;
}

h1,
h2,
h3,
h4,
label,
button {
    font-family: "Open Sans", sans-serif;
    color: $dark-blue-grey;
    margin: 0;
    padding: 0;
}

p {
    color: $cool-grey;
}

//----------------------------------------------------------
//Makes sure content doesnt exceed a specific width
//----------------------------------------------------------
.container {
    max-width: $content-width;
    margin: auto;
}

// Message Component
#errorMessage,
#errorMessage * {
    color: darkred;
}

#successMessage,
#successMessage * {
    color: darkgreen;
}

#errorMessage, #successMessage {
    position: fixed;
    bottom: 0px;
    width: auto;
    min-width: 300px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 9999;
}