.backdrop {
    position: fixed;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    top: 0;
    animation: show-backdrop 0.25s ease-in-out;
    z-index: 20;
}

@keyframes show-backdrop {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
