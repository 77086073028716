.hamburger {
    position: fixed;
    z-index: 1000;
    right: 45px;
    top: 35px;
    cursor: pointer;

    @media (min-width: $lg-breakpoint) {
        display: none;
    }

    &__rows {
        width: 25px;
        height: 3px;
        background-color: #000000;
        align-self: center;

        &:before {
            content: "";
            width: 25px;
            height: 3px;
            background-color: inherit;
            position: absolute;
            top: -7px;
            transition: all 0.5s ease-in-out;
        }

        &:after {
            content: "";
            width: 25px;
            height: 3px;
            background-color: inherit;
            position: absolute;
            top: 7px;
            transition: all 0.5s ease-in-out;
        }
    }

    &__box {
        height: 20px;
        position: absolute;
        width: 100%;
        top: -10px;
    }
}

.close {
    .hamburger__rows {
        height: 0;

        &:before {
            top: 0;
            transform: rotate(45deg);
        }

        &:after {
            top: 0;
            transform: rotate(-45deg);
        }
    }
}
