.logout-home-page {
    text-align: center;
    padding-top: 80px;
    margin-top: 80px;
    min-height: calc(100vh - 80px);
    overflow: hidden;

    @media (min-width: $lg-breakpoint) {
        margin-top: 0;
    }

    #logout-text-container {
        position: absolute;
        display: block;
        text-align: left;
        padding: 0 70px;

        @media (min-width: $md-breakpoint) {
            transform: rotateZ(-28deg);
            top: 25vh;
            left: 20vw;
        }

        @media (min-width: $xxl-breakpoint) {
            transform: rotateZ(-35deg) scale(1.4);
            top: 22vh;
            left: 28vw;
        }

        h1 {
            font-size: 25px;
            margin-bottom: 20px;

            @media (min-width: $xxl-breakpoint) {
                font-size: 72px !important;
                margin-left: -200px;
            }

            @media (min-width: $md-breakpoint) {
                margin-bottom: 15px;
                font-size: 45px;
                margin-left: -100px;
            }

            span {
                color: $pinkish-orange;
            }
        }

        h2 {
            font-size: 15px;
            margin: auto;
            border-radius: 15px;
            z-index: 1000;

            span {
                background: white;
            }

            @media (min-width: $md-breakpoint) {
                font-size: 24px;
                margin: 30px 0px;
            }

            @media (min-width: $xxl-breakpoint) {
                margin: 50px 0px;
            }
        }
    }

    #logout-home-icons {
        display: flex;
        flex-direction: row;

        @media (min-width: $md-breakpoint) {
            margin-left: 100px;
        }

        @media (min-width: $xxl-breakpoint) {
            margin-left: 200px;
        }

        @media (max-width: 767px) and (orientation: portrait) {
            flex-direction: column;
        }

        .icon-wrap {
            position: relative;
            width: 100px;

            .icon {
                background-size: cover;
                background-position: left top;
                border: 3px solid lightgray;
                height: 75px;
                width: 75px;
                border-radius: 50%;
                background-color: white;
                margin-top: 25px;
            }

            &:nth-of-type(1) {
                .icon {
                    background-image: url("../../../assets/pages/home/banking.png");
                }
            }

            &:nth-of-type(2) {
                .icon {
                    background-image: url("../../../assets/pages/home/credit.png");
                }
            }

            &:nth-of-type(3) {
                .icon {
                    background-image: url("../../../assets/pages/home/pos.png");
                }
            }

            &:nth-of-type(4) {
                .icon {
                    background-image: url("../../../assets/pages/home/cards.png");
                }
            }

            &:nth-of-type(5) {
                .icon {
                    background-image: url("../../../assets/pages/home/service.png");
                }
            }
        }
    }

    .p5Canvas {
        position: fixed !important;
        left: 0;
        top: 0;
        bottom: auto;
        z-index: -1 !important;

        @media (max-width: $md-breakpoint) {
            bottom: -350px;
            top: auto;
        }

        @media (min-width: $xxl-breakpoint) {
            bottom: -100px;
            top: auto;
        }
    }
    .first-section {
        height: calc(100vh - 160px);
        h1 {
            color: $pinkish-orange;
            font-size: 40px;
            font-weight: 800;
            margin-top: 150px;
            margin-bottom: 50px;
        }

        p {
            color: $dark-blue-grey;
            font-size: 24px;
            width: 70%;
            margin: auto;
            font-weight: 600;
        }
    }

    .first-section-position-fix {
        height: 90vh;
        opacity: 0;
        position: relative;
        z-index: -2;
    }

    .second-section {
        background-color: $dark-blue-grey;
        color: #fff;
        background-size: cover;
        background-position: bottom;

        &__inner {
            padding-top: 100px;
            padding-bottom: 100px;
            h1 {
                color: #fff;
                font-size: 40px;

                &.second-header {
                    margin-bottom: 30px;
                }
            }

            p {
                color: #fff;
                margin: auto;
                padding: 0 30px;
                font-size: 20px;
                margin-bottom: 50px;

                @media (min-width: $lg-breakpoint) {
                    width: 60%;
                }
            }
        }
    }

    .third-section {
        background-color: #fff;

        &__inner {
            padding-top: 150px;
            padding-bottom: 100px;

            @media (min-width: $lg-breakpoint) {
                display: flex;
                justify-content: space-between;
                padding-left: $desktop-padding;
                padding-right: $desktop-padding;
                max-width: 1440px;
                margin: auto;
            }

            &__col {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 50px;

                &__circle {
                    width: 105px;
                    height: 105px;
                    background-image: linear-gradient(to left, #ff8330, #ff9d5c);
                    border-radius: 50%;
                    margin-bottom: 22px;
                    border: 5px solid $pinkish-orange;
                    background-size: cover;
                }

                &:nth-of-type(1) {
                    .third-section__inner__col__circle {
                        background-image: url("../../../assets/pages/home/banking.png");
                    }
                }

                &:nth-of-type(2) {
                    .third-section__inner__col__circle {
                        background-image: url("../../../assets/pages/home/credit.png");
                    }
                }

                &:nth-of-type(3) {
                    .third-section__inner__col__circle {
                        background-image: url("../../../assets/pages/home/pos.png");
                    }
                }

                &:nth-of-type(4) {
                    .third-section__inner__col__circle {
                        background-image: url("../../../assets/pages/home/cards.png");
                    }
                }

                &:nth-of-type(5) {
                    .third-section__inner__col__circle {
                        background-image: url("../../../assets/pages/home/service.png");
                    }
                }

                &__title {
                }
            }
        }
    }
}
