.sidebar {
    position: fixed;
    top: 0;
    z-index: 500;
    height: 100vh;
    width: 100%;
    background-color: #fff;
    transform: translateX(100vw);
    transition: transform 0.2s ease-in-out;
    padding-top: 15px;
    padding-left: 30px;

    @media (min-width: $lg-breakpoint) {
        display: none;
    }

    &__logo {
        margin-bottom: 40px;
    }

    &__links {
        display: flex;
        flex-direction: column;

        a {
            color: $dark-blue-grey;
            font-size: 18px;
            font-weight: 600;
            display: flex;
            margin-bottom: 50px;

            img {
                margin-top: 3px;
            }

            &.active {
                color: $pinkish-orange;
            }
        }

        button {
            color: $dark-blue-grey;
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 50px;
            border: 0;
            background-color: transparent;
            cursor: pointer;
            text-align: left;
            outline: none;
        }
    }
}

.show-sidebar {
    transform: translateX(10vw);
}
