.auth {
    width: 88% !important;

    @media (min-width: $md-breakpoint) {
        width: 500px !important;
    }

    &.--lg{
        @media (min-width: $md-breakpoint) {
            width: 50%!important;
        }
    }

    .input_group{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @media (max-width: $md-breakpoint) {
            flex-direction: column;
        }

        .content{
            width: 45%;
        }
    }

    .captcha_container{
        display: flex;
        justify-content: center;
    }

    .auth-input-wrapper {
        margin-bottom: 15px;
        position: relative;

        &.--halfed{
            width: 48%;
            @media (max-width: $md-breakpoint) {
                width: 100%;
            }
        }

        .instruction {
            position: absolute;
            font-size: 8px;
            margin-bottom: 0;
            opacity: 0.5;
            top: 5px;
            left: 60px;

            @media (min-width: $md-breakpoint) {
                left: 70px;
                font-size: 10px;
                top: 3px;
            }
        }

        textarea{
            margin: 0;
            max-width: 100%;
            flex: 1 0 auto;
            outline: 0;
            -webkit-tap-highlight-color: rgba(255,255,255,0);
            text-align: left;
            line-height: 1.21428571em;
            font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
            padding: .67857143em 1em;
            background: #fff;
            border: 1px solid rgba(34,36,38,.15);
            color: rgba(0,0,0,.87);
            border-radius: .28571429rem;
            transition: box-shadow .1s ease,border-color .1s ease;
            box-shadow: none;
            width: 100%;
        }

        label {
            display: inline-block;
            margin-bottom: 5px;
        }

        .error {
            div[role="alert"] {
                color: #ff0000;
            }
            input {
                border: 1px solid #ff0000;
                border-radius: 5px;
                width: 100%;
            }
        }
    }

    .btn.orange-border {
        display: inline-flex !important;
        align-items: center;
        margin-left: 20px;
    }

    &.about-modal {
        width: 80% !important;

        .content {
            display: flex;
            align-items: center;

            @media (max-width: $md-breakpoint) {
                flex-direction: column;
                text-align: center;
                padding: 31px !important;
            }

            .img {
                border: 2px solid $pinkish-orange;
                height: 200px;
                width: 200px;
                background-size: cover;
                flex-shrink: 0;
                margin-right: 50px;
                border-radius: 50%;

                @media (max-width: $md-breakpoint) {
                    margin-right: 0px;
                    margin-bottom: 20px;
                }
            }

            .details {
                font-size: 28px;

                @media (max-width: $md-breakpoint) {
                    font-size: 18px;
                }
            }
        }
    }
}

.ui.modal {
    .actions {
        padding: 1rem 1rem 1rem !important;
        display: flex;
        justify-content: flex-end;

        @media (min-width: $md-breakpoint) {
            padding: 1.5rem !important;
        }
    }

    .auth-error {
        color: #ff0000;
        text-align: center;
    }

    .auth-success {
        color: green;
        text-align: center;
        margin: 20px 0px 10px;
    }

    .forgot-password {
        background-color: transparent;
        border: none;
        margin: auto;
        display: block;
        font-style: italic;
        text-decoration: underline;
        font-size: 11px;
        color: $pinkish-orange;
        cursor: pointer;

        &:hover {
            color: $dark-blue-grey;
        }
    }
}
.forms-error {
    color: #ff0000;
    text-align: center;
    margin: 10px 0;
}
.flexed{
    display: flex;
    justify-content: flex-end;
    .btn.orange-border{
        display: flex;
        align-items: center;
    }
}
