.carousel {
    margin-bottom: 50px;
    .card {
        width: 350px !important;
        outline: none;
        cursor: pointer;
        box-sizing: border-box;
        box-shadow: 0 4px 16px 0 rgba(48, 96, 255, 0.1);
        border-radius: 6px;
        padding: 25px;
        position: relative;

        &:hover .card-name,
        &:hover .label {
            transition: 0.5s all;
            color: $pinkish-orange !important;
        }

        @media (min-width: $lg-breakpoint) {
            width: 350px !important;
        }

        .card-recommendation-level {
            position: absolute;
            top: 12px;
            right: 12px;
        }
        .img-wrapper {
            border-radius: 6px;
            margin-bottom: 24px;
            overflow: hidden;

            img {
                width: 100%;
                object-fit: contain;
                object-position: center;
                height: 180px;
                position: relative;
            }
        }

        .card-name {
            margin-bottom: 10px;
            font-weight: 500;
        }

        .card-category {
            color: $pinkish-orange;
            font-weight: 500;
        }

        .star-rating {
            margin-bottom: 20px;
        }

        .row {
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;
        }

        .label-wrapper {
            margin-bottom: 10px;
        }

        .label {
            margin: 5px;
            margin-left: 0px;
            font-size: 0.8em;
        }
    }

    .slick-track {
        .slick-slide {
            margin-bottom: 50px;
            display: flex;
            justify-content: center;

            &:last-of-type {
                margin-bottom: 0px;
            }
        }
    }

    .slick-arrow.slick-next,
    .slick-arrow.slick-prev {
        &:before {
            color: $pinkish-orange;
        }
    }
}
