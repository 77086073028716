.nav {
    display: flex;
    align-items: center;
    height: 80px;
    padding: 0 $mobile-padding;
    box-shadow: 0 4px 16px 0 rgba(48, 96, 255, 0.1);
    position: fixed;
    width: 100%;
    background-color: #fff;
    top: 0;
    z-index: 10;

    @media (min-width: $xxl-breakpoint) {
        padding-left: 10vw !important;
        padding-right: 10vw !important;
        position: relative;
    }

    @media (min-width: $lg-breakpoint) {
        justify-content: space-between;
        padding: 0 $desktop-padding;
        position: relative;
    }

    &__logo {
    }

    &__links {
        display: none;

        @media (min-width: $lg-breakpoint) {
            display: flex;
            list-style: none;
        }

        a {
            color: $dark-blue-grey;
            font-size: 18px;
            font-weight: 600;
            margin-right: 24px;
            display: flex;
            white-space: nowrap;

            &:last-child {
                margin-right: 0px;
            }

            &.active {
                color: $pinkish-orange;
            }

            img {
                margin-top: 3px;
            }
        }

        button {
            color: $dark-blue-grey;
            font-size: 18px;
            font-weight: 600;
            margin-right: 24px;
            border: 0;
            background-color: transparent;
            cursor: pointer;
            outline: none;

            &:last-child {
                margin-right: 0px;
            }
        }
    }
}
