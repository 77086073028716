.market-place-page {
    margin-top: 80px;

    @media (min-width: $xxl-breakpoint) {
        padding-right: 10vw;
        padding-left: 10vw;
    }

    @media (min-width: $lg-breakpoint) {
        margin-top: 0;
    }

    .header {
        display: flex;
        justify-content: space-between;
        // background-color: #f9f9f9;
        background-color: #fff;
        flex-direction: column;

        .left-column {
            background-image: linear-gradient(169deg, #ff9d5c 19%, #ff8330 104%);
            padding-left: $desktop-padding;
            display: flex;
            align-items: center;
            height: 60px;

            @media (min-width: $lg-breakpoint) {
                width: 322px;
                border-top-right-radius: 6px;
                border-bottom-right-radius: 6px;
            }

            @media (max-width: $lg-breakpoint) {
                padding-left: 45px !important;
            }

            h3 {
                color: #fff;
            }
        }

        .right-column {
            display: flex;
            align-items: center;
            flex: 1;
            justify-content: center;

            @media (min-width: $lg-breakpoint) {
                padding-right: $desktop-padding;
                justify-content: flex-start;
                flex: 0;
            }

            .filter-header {
                margin-right: 38px;
            }

            .filter-col {
                margin-right: 24px;
                color: #182c49;
                .divider.default.text {
                    color: #182c49;
                }

                &:last-child {
                    margin-right: 0;
                }
            }

            .divider {
                font-size: 11px;
                white-space: nowrap;
            }
        }
    }

    .content {
        padding: 35px 45px 32px;

        @media (min-width: $lg-breakpoint) {
            padding: 35px $desktop-padding 32px;
        }

        .filter {
            display: flex;
            align-items: center;
            flex: 1;
            justify-content: flex-start;
            margin-bottom: 40px;

            .filter-header {
                margin-right: 38px;
            }

            .filter-col {
                margin-right: 24px;
                // font-size: 0px;

                &:last-child {
                    margin-right: 0;
                }
            }

            .divider {
                font-size: 15px;
                white-space: nowrap;
                color: #182c49;
                @media (max-width: $md-breakpoint) {
                    color: #182c49;
                    font-size: 12.9px;
                }
            }
        }

        h1 {
            margin-bottom: 32px;
        }
    }
}

.ui.default.dropdown:not(.button) > .text,
.ui.dropdown:not(.button) > .default.text {
    color: #182c49;
}
