.resources-page {
    padding: 30px $mobile-padding;
    margin-top: 80px;

    @media (min-width: $xxl-breakpoint) {
        padding: 40px 18% !important;
    }

    @media (min-width: $lg-breakpoint) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 70px;
        align-items: flex-start;
        padding: 40px $desktop-padding;
        margin: 0;
    }

    .column {
        position: relative;

        &__read_more{
            height: 50px;
            width: 50px;
            border-radius: 50%;
            background: $pinkish-orange;
            position: absolute;
            top: 50%;
            left: calc(100% - 25px);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;
            cursor: pointer;
            transition: 200ms;

            &:hover{
                transform: scale(1.07);
            }
            &:active{
                transform: scale(1.02);
            }

            .arrow.right.icon{
                color: white;
                font-size: 20px;

            }
        }
        .news{
            padding: 35px;
            box-shadow: 0 4px 16px 0 rgba(48, 96, 255, 0.1);
            border-radius: 6px;

            h1 {
                margin-bottom: 15px;
            }

            &__imagewrapper{
                img{
                    height: 400px;
                    width: 100%;
                    object-fit: cover;
                    @media (max-width: $lg-breakpoint) {
                        height: 300px;
                    }
                }
            }
            &__date{
                margin-bottom: 20px;
                
            }
            &__content{
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 8;
                overflow: hidden;
            }
        }
        @media (max-width: $lg-breakpoint) {
            margin-bottom: 50px;
            &__read_more{
                left: calc(100% - 25px);
                top: 45%;
                height: 40px;
                width: 40px;
            }
            .arrow.right.icon{
                font-size: 17px;
            }
        }
    }

    .ui.modal > .content {
        line-height: 1.5;
    }
}
