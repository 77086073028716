.btn {
    background-color: #fff;
    border-radius: 4px;
    color: $pinkish-orange;
    height: 32px;
    padding: 0 20px;
    border: 1px solid;
    cursor: pointer;
    outline: transparent;

    @media (min-width: $md-breakpoint) {
        height: 40px;
        padding: 0 25px;
    }

    //--------------------------------------------------
    //Button Variants
    //--------------------------------------------------
    &.white-border {
        border-color: #fff;
    }

    &.orange-border {
        border-color: $pinkish-orange;

        &:hover {
            background-color: $dark-blue-grey;
            border-color: $dark-blue-grey;
            color: #fff;
        }
    }

    &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
        background-color: #fff !important;
        border-color: $pinkish-orange !important;
        color: $pinkish-orange !important;
    }
}
