.home-page {
    overflow: hidden;
    .home-banner {
        height: 350px;
        min-height: 350px;
        background-image: linear-gradient(to right, #ff8330, #ff9d5c);
        display: flex;
        margin-top: 80px;
        width: 110%;
        padding: 0px 10% 90px 10% !important;
        margin-left: -5%;
        border-bottom-left-radius: 50%;
        border-bottom-right-radius: 50%;
        position: relative;

        @media (min-width: $md-breakpoint) {
            padding: 90px $tablet-padding 0;
        }

        @media (min-width: $lg-breakpoint) {
            padding: 90px $desktop-padding 0;
            margin-top: 0px;
        }

        &__center-column {
            display: flex;
            flex-direction: column;
            text-align: center;
            width: 100%;
            align-items: center;
            justify-content: center;
            margin-top: -40px;

            h1 {
                font-size: 34px;
                margin-bottom: 50px !important;
                color: #fff;

                @media (min-width: $xl-breakpoint) {
                    margin-bottom: 0px;
                    font-size: 48px;
                }
            }

            h3 {
                font-weight: 400;
                line-height: 1.5;
                margin-bottom: 40px;
                color: #fff;
            }

            button:hover {
                transform: scale(1.1);
                transition: 0.5s all;
            }
        }

        &__icon-row {
            position: absolute;
            bottom: 0px;
            display: flex !important;
            flex-direction: row;
            justify-content: space-around !important;
            width: 60%;
            left: 50%;
            transform: translateX(-50%);

            .icon-wrap {
                flex-basis: 20%;
                position: relative;
                bottom: 0px;

                h3 {
                    color: white;
                    text-align: center;
                }

                .icon {
                    background-size: cover;
                    background-position: center left;
                    border: 5px solid lightgray;
                    height: 60px;
                    width: 60px;
                    border-radius: 50%;
                    background-color: white;
                    margin: auto;
                    margin-top: 15px;

                    @media (min-width: $lg-breakpoint) {
                        height: 125px;
                        width: 125px;
                        margin-top: 25px;
                    }
                }

                &:nth-of-type(1) {
                    bottom: -15px !important;

                    @media (min-width: $lg-breakpoint) {
                        bottom: -25px !important;
                    }

                    .icon {
                        background-image: url("../../../assets/pages/home/banking.png");
                    }
                }

                &:nth-of-type(2) {
                    bottom: -35px !important;

                    @media (min-width: $lg-breakpoint) {
                        bottom: -45px !important;
                    }

                    .icon {
                        background-image: url("../../../assets/pages/home/credit.png");
                    }
                }

                &:nth-of-type(3) {
                    bottom: -45px !important;

                    @media (min-width: $lg-breakpoint) {
                        bottom: -55px !important;
                    }

                    .icon {
                        background-image: url("../../../assets/pages/home/pos.png");
                    }
                }

                &:nth-of-type(4) {
                    bottom: -35px !important;

                    @media (min-width: $lg-breakpoint) {
                        bottom: -45px !important;
                    }

                    .icon {
                        background-image: url("../../../assets/pages/home/cards.png");
                    }
                }

                &:nth-of-type(5) {
                    bottom: -15px !important;

                    @media (min-width: $lg-breakpoint) {
                        bottom: -25px !important;
                    }

                    .icon {
                        background-image: url("../../../assets/pages/home/service.png");
                    }
                }
            }

            @media (min-width: $md-breakpoint) {
                display: block;
                flex-basis: 50%;
                flex-shrink: 0;
            }

            img {
                @media (min-width: $md-breakpoint) {
                    transform: translateX(-90px);
                    width: 160%;
                }
                @media (min-width: $xl-breakpoint) {
                    transform: translateX(-140px);
                    width: auto;
                }
            }
        }
    }

    .content {
        padding: 90px $mobile-padding;

        &__left-column {
            display: block;

            @media (min-width: $xl-breakpoint) {
                display: grid;
                grid-template-columns: 60% 40%;
                grid-column-gap: 40px;
                grid-row-gap: 0px;
                margin: auto;
                padding: 90px $desktop-padding;
                max-width: 1440px;
            }

            &__card-wrapper {
                margin-bottom: 40px;

                .info-card {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    padding: 25px;
                    box-shadow: 0 4px 16px 0 rgba(48, 96, 255, 0.1);
                    border-radius: 6px;

                    @media (min-width: $md-breakpoint) {
                        padding: 35px;
                    }

                    .title {
                        margin-bottom: 45px;
                        display: flex;

                        .img-wrapper {
                            margin-right: 16px;
                            width: 30px;

                            @media (min-width: $md-breakpoint) {
                                width: 36px;
                            }

                            img {
                                width: 100%;
                            }
                        }
                    }

                    .d-block {
                        display: block !important;
                    }

                    .details {
                        display: -webkit-box;
                        -webkit-line-clamp: 4;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        margin-bottom: 24px;
                    }

                    .btn-wrapper {
                        align-self: flex-end;
                    }
                }
            }

            &__form-contact-wrapper {
                margin: auto;
                margin-bottom: 70px;

                @media (min-width: $xl-breakpoint) {
                    margin: 0px;
                    margin-bottom: 40px;
                }

                &.contact-info-card {
                    .form-contact form .input-wrapper .field {
                        @media (min-width: $lg-breakpoint) {
                            grid-template-columns: 1fr 1fr;
                        }
                    }
                }
            }
        }

        &__right-column {
            box-shadow: 0 4px 16px 0 rgba(48, 96, 255, 0.1);
            border-radius: 6px;
            padding: 35px;

            @media (min-width: $md-breakpoint) {
            }

            .considerations {
                color: #a1a6ac;
                margin-left: 15px;
                margin-bottom: 50px;
                font-size: 100%;

                .consideration {
                    line-height: 26px;
                }
            }

            .title {
                display: flex;
                margin-bottom: 31px;

                .img-wrapper {
                    margin-right: 16px;
                    width: 30px;

                    @media (min-width: $md-breakpoint) {
                        width: 36px;
                    }

                    img {
                        width: 100%;
                    }
                }
            }

            .credit-card-info {
                margin-bottom: 75px;

                .card {
                    margin-bottom: 24px;

                    .img-wrapper {
                        // max-width: 366px;
                        img {
                            object-fit: cover;
                            width: 100%;
                            height: 400px;
                        }
                    }
                }

                h3 {
                    margin-bottom: 10px;
                }

                .star-rating-wrapper {
                    margin-bottom: 10px;
                }
            }

            .btn-wrapper {
                button {
                    width: 100%;
                }
            }
        }
    }
}
