.loading-screen {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 40000;

    // img {
    //     animation: show-logo 1s ease-in-out infinite;
    // }
}

// @keyframes show-logo {
//     0% {
//         opacity: 0;
//     }

//     100% {
//         opacity: 1;
//         transform: scale(1.2);
//     }
// }
