//-------------------------------------------
//Common spacing
//-------------------------------------------
$desktop-padding: 70px;
$tablet-padding: 40px;
$mobile-padding: 20px;

//-------------------------------------------
//Colours
//-------------------------------------------
$pinkish-orange: #ff7943;
$dark-blue-grey: #182c49;
$cool-grey: #a1a6ac;
$gold: #febc48;

//-------------------------------------------
//Breakpoints
//-------------------------------------------
$xxl-breakpoint: 1921px;
$xl-breakpoint: 1140px;
$lg-breakpoint: 992px;
$md-breakpoint: 768px;
$sm-breakpoint: 640px;
$xs-breakpoint: 480px;

$content-width: 1440px;
