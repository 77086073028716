h1 {
    font-size: 22px;

    @media (min-width: $lg-breakpoint) {
        font-size: 26px;
    }
}

h2 {
    font-size: 20px;

    @media (min-width: $lg-breakpoint) {
        font-size: 24px;
    }
}

h3 {
    font-size: 14px;

    @media (min-width: $lg-breakpoint) {
        //This is currently set because of the home page banner
        font-size: 18px;
    }
}

h4 {
    font-size: 14px;
    font-weight: 600;
}

p {
    font-size: 12px;
    line-height: 1.6;

    @media (min-width: $md-breakpoint) {
        font-size: 14px;
    }
}

label {
    font-weight: 600;
    font-size: 12px;

    @media (min-width: $md-breakpoint) {
        font-size: 14px;
    }
}

button {
    font-size: 12px;

    @media (min-width: $md-breakpoint) {
        font-size: 14px;
    }
}
