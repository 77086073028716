.form-contact {
    box-shadow: 0 4px 16px 0 rgba(48, 96, 255, 0.1);
    border-radius: 30px;

    textarea[disabled] {
        background: transparent !important;
        color: #182c49 !important;
        border: 0 !important;
        font-weight: 600 !important;
    }

    .title {
        display: flex;
        align-items: center;
        padding: 25px;
        // background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.15));
        background: rgba(0, 0, 0, 0.1);
        border-top-right-radius: 30px;
        border-top-left-radius: 30px;

        @media (min-width: $md-breakpoint) {
            padding: 20px 35px;
        }

        .img-wrapper {
            margin-right: 16px;
            width: 30px;

            @media (min-width: $md-breakpoint) {
                width: 36px;
            }

            img {
                width: 100%;
            }
        }
    }

    form {
        padding: 25px;
        // background: linear-gradient(to right, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.15));
        background: rgba(0, 0, 0, 0.1);

        &:last-child {
            border-bottom-right-radius: 30px;
            border-bottom-left-radius: 30px;
        }

        @media (min-width: $md-breakpoint) {
            padding: 35px;
        }
        .input-wrapper {
            margin-bottom: 5px;
            .ui.disabled.input,
            .ui.input:not(.disabled) input[disabled] {
                opacity: 1;
            }
            // &.disabled.ui.input>input::-webkit-input-placeholder{
            //     color: red;
            //     opacity: 1;
            // }
            .error.field {
                position: relative;
                .ui.pointing.above.prompt.label {
                    position: absolute;
                    bottom: -31px;
                    right: 0;
                    z-index: 100;
                }
            }
            &.dropdown {
                display: grid;
                grid-template-columns: 1fr 500px;
            }
            &.textarea {
                display: flex;
                justify-content: space-between;
                flex-wrap: no-wrap;
                width: 100%;
            }
            &.textarea > textarea[disabled] {
                background: #f0f0f0;
                color: #a1a6ac;
            }
            &.textarea textarea {
                color: rgba(0, 0, 0, 0.87);
                padding: 7px 14px;
                border: 1px solid rgba(34, 36, 38, 0.15);
            }
            &.textarea textarea:focus {
                border: 1px solid #85b7d9 !important;
                outline: none !important;
            }

            @media (min-width: $md-breakpoint) {
                &.textarea textarea {
                    max-width: 200px;
                    min-width: 200px;
                }
            }
            @media (max-width: 767px) {
                &.textarea {
                    flex-direction: column;
                }
                &.textarea textarea {
                    max-width: 100%;
                    width: 100%;
                }
            }

            @media (min-width: $lg-breakpoint) {
                &.textarea textarea {
                    max-width: 500px;
                    min-width: 500px;
                    width: 500px;
                    color: rgba(0, 0, 0, 0.87);
                    padding: 7px 14px;
                    border: 1px solid rgba(34, 36, 38, 0.15);
                }
            }

            //-------------------------------
            //Semantic ui overrides
            //-------------------------------
            .field {
                @media (min-width: $md-breakpoint) {
                    display: grid;
                    grid-template-columns: 1fr 200px;
                    grid-gap: 20px;
                }

                @media (min-width: $lg-breakpoint) {
                    grid-template-columns: 1fr 500px;
                }

                .input {
                    @media (min-width: $md-breakpoint) {
                        flex: 1;
                    }
                }

                .disabled {
                    input {
                        // background-color: rgba(34, 36, 38, 0.15) !important;
                        background: transparent !important;
                        border: none;
                        color: #182c49 !important;
                        font-weight: 600;
                        letter-spacing: 0.3px;
                    }
                }
            }
        }

        .dropdown-wrapper {
            @media (min-width: $md-breakpoint) {
                display: grid;
                grid-template-columns: 1fr 200px;
                grid-gap: 20px;
            }

            @media (min-width: $lg-breakpoint) {
                grid-template-columns: 1fr 500px;
            }

            .input {
                @media (min-width: $md-breakpoint) {
                    flex: 1;
                }
            }

            .ui.disabled.fluid.search.selection.dropdown {
                // background-color: rgba(34, 36, 38, 0.15) !important;
                background-color: transparent !important;
            }

            .ui.default.dropdown:not(.button) > .text,
            .ui.dropdown:not(.button) > .default.text {
                color: rgba(0, 0, 0, 0.87);
            }
        }

        .btn-wrapper {
            text-align: right;
            margin-top: 24px;
        }
    }

    .dropdown-wrapper.disabled .dropdown {
        border: 0px !important;
        font-weight: 600 !important;
        letter-spacing: 0.3px;
        font-size: 1em !important;
        opacity: 0.8;

        .dropdown.icon {
            display: none;
        }
    }

    .disabled,
    .disabled *,
    .disabled input::placeholder,
    .disabled textarea::placeholder {
        border: 0px !important;
        font-weight: 600 !important;
        letter-spacing: 0.3px !important;
        font-size: 14px !important;
        opacity: 1 !important;
        color: #182c49 !important;
        font-family: "Open Sans", sans-serif !important;
    }
}
